import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContentAndPicture from "../components/content-and-picture"

import { HeroStatic } from "../components/hero"
import ValueBox from "../components/valuebox_new"
import Schedule from "../components/schedule"
import IIIStep from "../components/3step"
import Testimonials from "../components/testimonials"
import { ContactFormTrial } from "../components/contactform"
import SideBlocks from "../components/side-blocks"

import { Container, H2, P } from "../components/styledcomponents"
import Button from "../components/button"


const BJJ = ({ location, data }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title=" Brazilian Jiu-Jitsu / BJJ in München - Team Laurien & Alex - kostenlose Probestunde vereinbaren"
        description=" Trainiere Brazilian Jiu-Jitsu / BJJ in unserer Kampfsportschule in München und lerne dich effektiv gegen stärkere, größere Gegner zu verteidigen!"
      />
      <HeroStatic
        title="Lass uns zusammen Gas geben!"
        img={data.hero1}
        imgAlt="BJJ in München"
        color="#ffffff"
        buttoncolor="#f17915"
      />
      <ValueBox
        values={[
          { id: 1, value: "Stressabbau" },
          { id: 2, value: "Körperliche Fitness & Mentale Fitness" },
          { id: 3, value: "Lösungsorientiertes Denken" },
          { id: 4, value: "Team Spirit" },
        ]}
      />
      <div>
        <ContactFormTrial page_source="general-Probetraining" />
      </div>
      <ContentAndPicture
        h2="Wir sind Lösungsanbieter"
        description={<>
          <P>
            Probleme können viele Formen annehmen:
          </P>
          <P>
            <ul>
              <li>
                Stress
              </li>
              <li>
                Angst
              </li>
              <li>
                sich nicht wohl im eigenen Körper fühlen
              </li>
              <li>
                Mobbing
              </li>
              <li>
                Mangel an Disziplin oder Motivation
              </li>
              <li>
                geringes Selbstvertrauen oder Selbstwertgefühl
              </li>
              <li>
                Traumatische Erfahrungen usw.
              </li>
            </ul>
            <strong>Probleme haben aber auch Lösungen:</strong> <br />
            Bewegung hilft beim Stress Abbau, Gewicht verlieren und fitter werden. Kampfsport lehrt einem mit Angst und Mobbing umzugehen,
            und Selbstdisziplin zu entwickeln. Dadurch werden auch Selbstvertrauen und Selbstwertgefühl gesteigert.
            Yoga & Kampfsport können beim Heilen unterstützen, da Körper und Geist wieder vereint werden.
          </P>
          <P>
            Wenn du herausfinden willst, wie wir dir helfen können, dann kontaktiere uns jetzt für ein unverbindliches Beratungsgepräch!
            Bei diesem Gespräch können wir dich und deine Ziele kennen lernen und eine kostenlose Probestunde vereinbaren.
          </P>
          <Button
            to={"#contact"}
          >        Unverbindliches Beratungsgespäch anfragen
          </Button>
        </>
        }
        imgLeft={data.hiptoss}
      />
      < SideBlocks >
        Unser Ziel ist, Menschen dabei zu helfen, erfolgreich zu sein, und nicht Gründe zu finden, warum sie es nicht können!
      </SideBlocks>

      <ContentAndPicture
        h2="Wie läuft eine Probestunde ab?"
        description={
          <>
            <P>
              Für eine Probestunde brauchst du keine Vorkentnisse, kein Fitnesslevel, keine besondere Ausrüstung. <br />
              Alles was du brauchst sind bequeme Sportklamotten, etwas zu trinken und den Willen etwas neues Auszuprobieren. <br />
              Bei deiner Probestunde werden wir dir zunächst unsere Räume zeigen, komme daher bitte mindestens 15 Minuten vor Beginn der Stunde.
              Dann werden wir dir erklären was genau für diese Stunde geplant ist.
              Kostenlose Probestunden bieten wir für BJJ, Kickboxen oder MMA (je nach Alter) und Yoga an. Bei unserem EMS Training fällt eine Leihgebühr für den EMS-Anzug an.
            </P>
            <Button
              to={"#contact"}
            >        Kostenlose Probestunde sichern
            </Button>
          </>
        }

        imgRight={data.heelhook}
      // h2="BJJ für Jugendliche und Erwachsene"
      // id="jugendliche-und-erwachsene"
      // description={
      //   <P>
      //     BJJ ist wie Blitzschach – Du lernst während Du mit Deinem Partner
      //     trainierst gleichzeitig Problemlösend zu denken und zu handeln.
      //     Deswegen verbessert BJJ Deine körperliche und mentale Fitness
      //     schnell. Zudem ist BJJ der perfekte Ausgleich zum Alltag, da Du in
      //     einer sicheren und freundschaftlichen Atmosphäre trainierst und Dich
      //     auspowerst.
      //     <br />
      //     BJJ Training:
      //   </P>
      // }
      // list={[
      //   "Steigert körperliche & mentale Fitness",
      //   "Baut Stress ab",
      //   "Macht Dich gesünder und fitter",
      //   "Steigert Selbstbewusstsein",
      // ]}
      />

      <SideBlocks>
        Fördert Konzentration | Verbessert das Selbstbewusstsein | Richtig Auspowern |
        Verbessert mentale Fitness | lösungsorientiertes Denken | Umgang
        mit Stress
      </SideBlocks>
      <Container backgroundColor="transparent" padding="1em 0" />
      <Container backgroundColor="transparent">
        <H2 center noLine>
          Wir versprechen Dir, Du fühlst Dich jederzeit wilkommen!
        </H2>
      </Container>
      <Testimonials
        title="Das sagen unsere Mitglieder:"
        values={[
          {
            id: 4,
            review:
              "Generell gibt mir das Training eine Struktur für den Tag (gerade als Student ist das sehr hilfreich). Außerdem kann ich im Training alles um mich herum für ein paar Stunden vergessen und mein Kopf ist danach viel freier. Mir fällt es auch viel leichter zu lernen, weil meine Frustrationstoleranz sich durch das Training echt verbessert hat.",
            reviewer: "Paul",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 1,
            review:
              "An BJJ liebe ich die Möglichkeit, trotz freundschaftlicher, kollegialer Atmosphäre eindeutig seine Grenzen aufgezeigt zu bekommen. Es gibt kein „hätte ich“, ‚„wenn ich“, „wäre ich“…Man ist konstant gezwungen besser zu werden, da es auf der Matte keine Ausflüchte gibt :D",
            reviewer: "Pascal",
            img:
              "https://cdn.shortpixel.ai/spai/q_glossy+ret_img+to_webp/https://lh4.ggpht.com/-tjAsb2ibil4/AAAAAAAAAAI/AAAAAAAAAAA/RIE7jEoWFnU/s128-c0x00000000-cc-rp-mo-ba2/photo.jpg",
          },
          {
            id: 2,
            reviewer: "Antigona",
            img:
              "https://lh3.ggpht.com/-EF1fjB92uWs/AAAAAAAAAAI/AAAAAAAAAAA/6Yz6efcUG2g/s128-c0x00000000-cc-rp-mo/photo.jpg",
            review:
              "Für mich ist BJJ einer der besten Wege Geistig vom Alltag abzuschalten, zu fokussieren und auch mich körperlich Auszugleichen, es ist Erholung für den Kopf und Seele bringt positive Energie und Motiviert ehrgeizig zu sein. Es hebt für mich einfach meine Lebensqualität.",
          },
        ]}
      />

      <IIIStep
        title="Jede größere Änderung beginnt mit einem Schritt!"
        description="Egal, ob Du nach der Arbeit Stress abbauen, abnehmen, Dich in Form bringen oder Dich körperlich oder mental herausfordern möchtest, Team Laurien & Alex hilft Dir dabei, Dein Ziel zu erreichen."
        pathname={location.pathname}
        link="#contact"
        values={[
          {
            id: 1,
            img: data.step1,
            value: "Schritt 1 ",
            statement: "Fordere mehr Informationen zum Training mit unserem Kontaktformular an",
          },
          {
            id: 2,
            img: data.step2,
            value: "Schritt 2",
            statement:
              "Wir melden uns bei dir und machen einen unverbindlichen und kostenlosen Termin für ein Probetraining mit dir aus.",
          },
          {
            id: 3,
            img: data.step3,
            value: "Schritt 3",
            statement:
              "Lerne uns und unsere Schule bei deinem Probetraining kennen. Wir freuen uns auf dich!",
          },
        ]}
      />

    </Layout>
  )
}
export const query = graphql`{
          hero1: file(relativePath: {eq: "BJJTrial/bjj-aufstellung.jpg"}) {
          childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
    }
  }
        hiptoss: file(relativePath: {eq: "BJJTrial/bjj-muenchen-passing.jpg"}) {
          childImageSharp {
          gatsbyImageData(
            width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        heelhook: file(relativePath: {eq: "Yoga-am-See-Team-Laurien-Alex-e1558013377416.jpeg"}) {
          childImageSharp {
          gatsbyImageData(
            width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        triangle: file(relativePath: {eq: "BJJ/BJJ-Muenchen-Triangle-Wuerger.jpg"}) {
          childImageSharp {
          gatsbyImageData(
            width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        kids: file(relativePath: {eq: "BJJ/BJJ_Kids-knee-slide-pass.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 900
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust1: file(
        relativePath: {eq: "Kampfsportschule-des-Jahres-Team-Laurien-Alex.jpg"}
        ) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust2: file(relativePath: {eq: "IBJJF-Zerifikat-TLA-BJJ.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust3: file(relativePath: {eq: "Deutsche-Meister-BJJ-IBJJF-Kickboxen.jpg"}) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust5: file(
        relativePath: {eq: "Yogalehrerzertifikat-in-indien-von-Lalit-Kumar-Himalaya-Yoga-Valley.jpg"}
        ) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust4: file(relativePath: {eq: "Reviews-Team-Laurien-Alex.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        trust6: file(
        relativePath: {eq: "Laurien-and-alex-with-Cadu-Francis-purple-belt-graduation.jpg"}
        ) {
          childImageSharp {
          gatsbyImageData(
            width: 450
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        step1: file(
        relativePath: {eq: "Persoenliche-Unterstuetzung-beim-kostenlosen-Probetraining-TLA-Muenchen.JPG"}
        ) {
          childImageSharp {
          gatsbyImageData(
            width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        step2: file(relativePath: {eq: "Spass-beim-Training-im-Team-Muenchen.JPG"}) {
          childImageSharp {
          gatsbyImageData(
            width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        step3: file(
        relativePath: {eq: "Fitness-verbessern-team-laurien-alex-chaturanga-liegestuetz.jpg"}
        ) {
          childImageSharp {
          gatsbyImageData(
            width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
        magnet: file(relativePath: {eq: "Cover_Bist_du_gestresst_atemuebungen.jpg"}) {
          childImageSharp {
          gatsbyImageData(
            width: 400
        quality: 100
        placeholder: NONE
        layout: CONSTRAINED
        )
    }
  }
}
        `
export default BJJ
